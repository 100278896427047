<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Housekeeper Task List') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
        </div>
        <a-table
          class="tasks-table"
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #partner="{ record }">
            <span class="task__user">{{ record.partner ? record.partner.name : '-' }}</span>
          </template>
          <template #user="{ record }">
            <span class="task__user">{{ record.user ? getUserFullname(record.user) : '-' }}</span>
          </template>
          <template #property="{ record }">
            <span class="col--nowrap">
              {{ record.room ? record.room.property.abbreviation : '' }}
            </span>
          </template>
          <template #assignedStatus="{ record }">
            <span class="col--nowrap">{{ record.status ? $t(record.status) : '' }}</span>
          </template>
          <template #room="{ record }">
            <span class="task__room">{{ record.room ? record.room.name : '' }}</span>
          </template>
          <template #cleaningTime="{ record }">
            <span class="col--nowrap">
              {{
                `${$filters.date(record.cleaningDate, { format: $variables.dateFormat })} ${
                  record.cleaningTime || ''
                }`
              }}
            </span>
          </template>
          <template #completedAt="{ record }">
            <span class="col--nowrap">
              {{ $filters.date(record.completedAt, { format: $variables.datetimeFormat }) }}
            </span>
          </template>
          <template #checkoutStatus="{ record }">
            {{ checkoutStatus(record) }}
          </template>
          <template #housekeepingStatus="{ record }">
            {{ $t(getHousekeepingStatus(record)) }}
          </template>
          <template #sameDayCheckin="{ record }">
            {{ record.sameDayCheckin ? $t('has-checkin') : $t('has-no-checkin') }}
            {{ record.sameDayCheckin ? `(${record.checkinTime})` : '' }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Housekeeper Task List": "Housekeeper Task List",
    "Housekeeper Company": "Housekeeper Company",
    "Housekeeper": "Housekeeper",
    "Assigned status": "Assigned status",
    "Cleaning time": "Cleaning time",
    "Checkout Status": "Checkout Status",
    "Property": "Property",
    "Room Name": "Room Name",
    "Housekeeping Status": "Housekeeping Status",
    "Finished time": "Finished time",
    "yes": "yes",
    "no": "no",
    "Same Day Checkin": "Same Day Checkin",
    "has-checkin": "yes",
    "has-no-checkin": "no"
  },
  "ja": {
    "Housekeeper Task List": "清掃担当者タスクリスト",
    "Housekeeper Company": "清掃会社",
    "Housekeeper": "清掃員",
    "Assigned status": "割当",
    "Cleaning time": "清掃日",
    "Checkout Status": "退室 ",
    "Property": "物件",
    "Room Name": "部屋",
    "Housekeeping Status": "清掃",
    "Finished time": "完了",
    "yes": "済",
    "no": "未",
    "Same Day Checkin": "同日",
    "has-checkin": "ある",
    "has-no-checkin": "ない"
  }
}
</i18n>

<script>
import pagination from '@/components/mixins/pagination';
import SearchForm from '@/views/housekeeper-tasks/components/SearchForm';
import { getFullname } from '@/utils/users';

export default {
  name: 'HousekeeperTasks',
  components: {
    SearchForm,
  },
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Assigned status'),
          dataIndex: 'status',
          slots: { customRender: 'assignedStatus' },
        },
        {
          title: this.$t('Cleaning time'),
          dataIndex: 'cleaningTime',
          slots: { customRender: 'cleaningTime' },
        },
        {
          title: this.$t('Finished time'),
          dataIndex: 'completedAt',
          slots: { customRender: 'completedAt' },
        },
        {
          title: this.$t('Checkout Status'),
          dataIndex: 'checkoutStatus',
          slots: { customRender: 'checkoutStatus' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'room',
          key: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Housekeeping Status'),
          key: 'housekeepingStatus',
          dataIndex: 'housekeepingStatus',
          slots: { customRender: 'housekeepingStatus' },
        },
        {
          title: this.$t('Same Day Checkin'),
          dataIndex: 'sameDayCheckin',
          key: 'sameDayCheckin',
          slots: { customRender: 'sameDayCheckin' },
        },
      ],
    };
  },
  created() {
    this.fetchData();

    if (!this.$store.getters.roles.includes('HKP')) {
      const cols = [
        {
          title: this.$t('Housekeeper'),
          dataIndex: 'user',
          slots: { customRender: 'user' },
        },
        {
          title: this.$t('Housekeeper Company'),
          dataIndex: 'partner',
          slots: { customRender: 'partner' },
        },
      ];

      if (this.$i18n.locale === 'ja' && this.$mq === 'mobile') {
        cols.pop();
      }

      this.columns.splice(1, 0, ...cols);
    }
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store
        .dispatch('housekeeper-tasks/list', this.$route.query)
        .then((res) => {
          const { list, pagination } = res;

          this.list = list.map((p, index) => {
            p._id = index + 1;

            return p;
          });

          if (pagination) {
            this.setPagination(pagination);
          }
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    getHousekeepingStatus(record) {
      const today = this.$filters.date(new Date(), 'YYYY-MM-DD');
      const completeDate = record.completedAt
        ? this.$filters.date(record.completedAt, 'YYYY-MM-DD')
        : '';

      if (today === completeDate) {
        return 'in-progress';
      }

      if (record.completedAt) {
        return 'yes';
      }

      return 'no';
    },
    checkoutStatus({ checkoutStatus }) {
      if (checkoutStatus === undefined || checkoutStatus === null) {
        return 'N.A.';
      }

      return checkoutStatus === true ? this.$t('yes') : this.$t('no');
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$store.dispatch('routers/addFirstLevelQuery', {
            query: this.$route.query,
            name: 'housekeeper-tasks',
          });
          this.$router.push({ name: 'housekeeper-task-overview', params: { id: record.id } });
        },
      };
    },
    getUserFullname(record) {
      return getFullname({
        fname: record.firstName,
        lname: record.lastName,
      });
    },
  },
};
</script>

<style lang="scss">
html[lang='ja'] .tasks-table {
  tr > th {
    padding: 16px 16px;
    white-space: nowrap;
  }

  .col--nowrap {
    display: block;
    width: 100%;
    white-space: nowrap;
  }

  .task__user,
  .task__room {
    display: block;
    word-wrap: break-word;
    word-break: break-word;
  }

  .task__user {
    width: 100px;
  }

  .task__room {
    width: 120px;
  }
}
</style>
